import './section.css';
import './apropos.scss';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/img/chevron-down_mid-light.png';
import { useState, useEffect } from 'react';

export default function Apropos(props) {
	const { t } = useTranslation(['translation', 'section_apropos']);

	const [sectionFlexBasis, setSectionFlexBasis] = useState("66vw");
	useEffect(() => {
		if (props.screen === "mid") setSectionFlexBasis("77vw");
		else setSectionFlexBasis("66vw");

	}, [props.screen]);
	return (
		<section className='section apropos'>
			<header><h1>{t('section_apropos:entete')}</h1></header>
			<article>
				{props.screen !== "small" ? <div className='h-separateur'></div> : ""}
				<section style={{ flexBasis: sectionFlexBasis }}>
					<h2>{t('section_apropos:titre')}</h2>
					<p >{t('section_apropos:corps')}</p>
				</section>
			</article>
			<img className="go-down" src={chevron} alt="allerEnBas" onClick={(e) => props.DownChevronClick(e)} />
		</section>
	);
}