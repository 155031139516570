import './section.css';
import './equipe.scss';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/img/chevron-down_mid-dark.png';
import upArrow from '../../../assets/img/arrow-up.png';
import Membre from './equipe_membre';
import { Modal } from 'antd';
import React, { useState } from 'react';


export default function Equipe(props) {
	const { t } = useTranslation(['translation', 'section_equipe']);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [membreCourant, setMembreCourant] = useState({ nom: "", bio: "" });

	let showModal = (membre) => {
		console.log("in show modal", membre);
		membre ? setMembreCourant(membre) : setMembreCourant({ nom: "", bio: "" });
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	return (
		<section className='section equipe'>
			<header><h1>{t('section_equipe:entete')}</h1></header>
			<div className="lesMembres">
				<h2 className={`${props.screen}`}>{t('section_equipe:titre')}</h2>
				<div className={`ombre ${props.screen}`}>
					<div className="liste">
						{Object.keys(t('section_equipe:membres', { returnObjects: true }))
							.map((key, index) => {
								let membre = t('section_equipe:membres', { returnObjects: true })[key];
								return <Membre key={index} prenom={membre.prenom} nom={membre.nom} fonction={membre.fonction} description={membre.description} bio={membre.bio} imgDir="../../../assets/img/equipe" screen={props.screen} modalbioclick={showModal} />;
							})}
					</div>
				</div>
			</div>
			<Modal title={membreCourant.nom} visible={isModalVisible} centered footer={null} closable={true} onOk={handleOk}
				onCancel={handleOk}>
				<p className="bioparagraph">{membreCourant.bio}</p>
			</Modal>
			<img className="go-down" src={chevron} alt="allerEnBas" onClick={(e) => props.DownChevronClick(e)} />
			<img className="go-up" src={upArrow} alt="allerEnHaut" onClick={(e) => props.UpArrowClick(e)} />
		</section>
	);
}