export default function Utils() {

	// rgb2rgba retourne une string de la forme "rgba(xxx,xxx,xxx,x.x)"
	//
	// {param} rgbString: string de la forme "rgb[a](255,255,255[,0.5])"
	// {param} opacity: float compris entre 0 et 1
	var rgb2rgba = (rgbString, opacity) => {
		rgbString = rgbString.trim();
		if (rgbString.substr(0, 1) === "#") {
			return hex2rgba(rgbString, opacity);
		}
		let colors = new RegExp('([0-9]+).{1,2}([0-9]+).{1,2}([0-9]+)', 'g').exec(rgbString);
		let o = opacity;
		if (o > 1) o = 1.0;
		if (o < 0) o = 0.0;
		return "rgba(" + colors[1] + "," + colors[2] + "," + colors[3] + "," + o + ")";
	}
	var hex2rgba = (rgbString, opacity) => {
		if (rgbString.substr(0, 1) !== "#") return "#000";
		let hexString = rgbString.replace('#', "").trim();
		let o = opacity ? opacity : 1.0;
		if (o > 1) o = 1.0;
		if (o < 0) o = 0.0;
		let hexOpacity = Math.round(o * 255).toString(16);
		if (hexOpacity.length === 1) {
			hexOpacity = hexOpacity + hexOpacity;
		}
		if (hexString.length === 3) {
			return "#" + hexString[0] + hexString[0] + hexString[1] + hexString[1] + hexString[2] + hexString[2] + hexOpacity;
		}
		else if (hexString.length === 6) {
			return "#" + hexString + hexOpacity;
		}
		else if (hexString.length === 8) {
			return "#" + hexString.substr(0, hexString.length - 2) + hexOpacity;
		}
		else return rgbString;
	}
	return {
		rgb2rgba: rgb2rgba,
		hex2rgba: hex2rgba
	}
}