import { useTranslation } from 'react-i18next';
import React from 'react';

const imageFromFile = require.context("../../../assets/img/equipe", true, /.jpg$/);

export default function Membre(props) {
	let nom = props.prenom + " " + props.nom;
	let titre = props.fonction;
	let descrp = props.description;
	let imgDir = props.imgDir;

	const { t } = useTranslation(['translation', 'section_equipe'])

	return (
		<div className={`membre ${props.screen}`}>
			<img className="imgMembre" src={imageFromFile(`./${props.prenom.toLowerCase() + "_" + props.nom.toLowerCase()}.jpg`).default} alt={imgDir + "/" + props.prenom.toLowerCase() + "_" + props.nom.toLowerCase() + ".jpg"} />
			<h1>{nom}</h1>
			<h2>{titre}</h2>
			<h3>{descrp}</h3>
			{props.bio?<p onClick={() => props.modalbioclick({ nom: nom, bio: props.bio })}>{t('section_equipe:lasuite')}</p>:""}
			
		</div>
	);

}