import './section.css';
import './contact.scss';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/img/logo.png';
import upArrow from '../../../assets/img/arrow-up.png';

export default function Contact(props) {
	const { t } = useTranslation(['translation', 'section_contact']);


	return (
		<section className='section contact'>
			<header><h1>{t('section_contact:entete')}</h1></header>
			<article>
				<section className={`${props.screen}`}>
					<h2>{t('section_contact:intro')}</h2>
					<p ><br /><strong>{t('section_contact:email')}</strong>
					<br /><strong>{t('section_contact:phone')}</strong>
					<br />{t('section_contact:country')}</p>
					<img className='' src={logo} alt="logo" />
				</section>
				<p className='copyright'>{t('section_contact:copyright')}</p>
			</article>
			<img className="go-up" src={upArrow} alt="allerEnHaut" onClick={(e) => props.UpArrowClick(e)} />
		</section>
	);
}