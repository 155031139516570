import './section.css';
import './service.scss';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/img/chevron-down_mid-light.png';
import upArrow from '../../../assets/img/arrow-up.png';

const iconFromFile = require.context("../../../assets/img/service", true, /.png$/);

export default function Service(props) {
	const { t } = useTranslation(['translation', 'section_service']);
	console.log(props)

	return (
		<section className='section service'>
			<header><h1>{t('section_service:entete')}</h1></header>
			<div className="services">
				<h2 className={`${props.screen}`}>{t('section_service:titre')}</h2>
				<div className={`liste ${props.screen}`}>
					{Object.keys(t('section_service:services', { returnObjects: true }))
						.map((key, index) => {
							let srvc = t('section_service:services', { returnObjects: true })[key];
							return (
								<div className="indivService" key={index}>
									<img className="imgService" src={iconFromFile(`./${key}.png`).default} alt={key + ".png"} />
									<h1>{srvc.titre.toUpperCase()}</h1>
									<p>{srvc.description}</p>
								</div>)
						})}
				</div>
			</div>
			<img className="go-down" src={chevron} alt="allerEnBas" onClick={(e) => props.DownChevronClick(e)} />
			<img className="go-up" src={upArrow} alt="allerEnHaut" onClick={(e) => props.UpArrowClick(e)} />
		</section>
	);
}