import './section.css';
import './valeur.scss';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/img/chevron-down_mid-dark.png';
import upArrow from '../../../assets/img/arrow-up.png';
import { useState, useEffect } from 'react';

export default function Valeur(props) {
	const { t } = useTranslation(['translation', 'section_valeur']);

	const [sectionFlexBasis, setSectionFlexBasis] = useState("66vw");
	useEffect(() => {
		if (props.screen === "mid") setSectionFlexBasis("77vw");
		else setSectionFlexBasis("66vw");

	}, [props.screen]);
	return (
		<section className='section valeur'>
			<header><h1>{t('section_valeur:entete')}</h1></header>
			<article>
				<section style={{ flexBasis: sectionFlexBasis }}>
					<h2>{t('section_valeur:titre')}</h2>
					<p ><q>{t('section_valeur:citation1')}</q><br />{t('section_valeur:corps1')}<br /><br />
						<q>{t('section_valeur:citation2')}</q><br />{t('section_valeur:corps2')}<br /><br />
						<q>{t('section_valeur:citation3')}</q><br />{t('section_valeur:corps3')}<br />
					</p>
				</section>
				{props.screen !== "small" ? <div className='h-separateur'></div> : ""}
			</article>
			<img className="go-down" src={chevron} alt="allerEnBas" onClick={(e) => props.DownChevronClick(e)} />
			<img className="go-up" src={upArrow} alt="allerEnHaut" onClick={(e) => props.UpArrowClick(e)} />
		</section>
	);
}