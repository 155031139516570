import './bandeau.css';
import logo_large from '../../../assets/img/logo_large.svg';
import logo_small from '../../../assets/img/logo_small.svg';
import i18n from '../../../i18n';
import React, { useEffect, useState, useRef } from 'react';
// import { useTranslation } from 'react-i18next';

export default function Bandeau(props) {
	const [currentLang, setCurrentLang] = useState(null);
	// const { t } = useTranslation(['translation', 'bandeau'])

	let logo = useRef(null);
	if (props.screen === "small") logo.current = logo_small;
	else logo.current = logo_large;
	// Souligne la langue utilisée à l'initialisation du bandeau
	useEffect(() => {
		if (i18n.language === undefined) return;
		let btnLang = document.getElementsByClassName('btn-lang')
		for (let i = 0; i < btnLang.length; i++) {
			btnLang[i].classList.remove('underline');
			if (btnLang[i].classList.contains('lang-' + i18n.language.substr(0, 2))) btnLang[i].classList.add('underline');
		}
	}, [currentLang]);

	useEffect(() => {
		if (props.screen === "small") logo.current = logo_small;
		else logo.current = logo_large;

	}, [props.screen]);

	let changeLang = function (lang) {
		i18n.changeLanguage(lang)
			.then(() => {
				setCurrentLang(i18n.language);
			},
				(reason) => {
					console.error("ERROR changing language to ", lang, " with message:", reason);
				}
			)
			.catch(reason => {
				console.error("ERROR changing language to ", lang, " with message:", reason);
			})
	}
	i18n.on("languageChanged", () => setCurrentLang(i18n.language));


	return (
		<div id='bandeau' className="bandeau">
			<img className={"logo-" + (props.screen === "small" ? "small" : "large")} src={logo.current} alt="logo 360Tourisme" />
			<div className='h-separateur'></div>
			<button className="btn-lang lang-fr" onClick={() => changeLang('fr')}>{props.screen === "small" ? "FR" : "FRANCAIS"}</button>
			<button className="btn-lang lang-en" onClick={() => changeLang('en')}>{props.screen === "small" ? "EN" : "ENGLISH"}</button>
			{/* <button className="btn-lang" onClick={() => console.log("GALLERY")}>{t('bandeau:photos')}</button> */}
		</div>

	);
}