import React, { Suspense, useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './components/home/home';

export default function App() {

  let getScreenSize = () => {
    // custom vh value for css on mobile, see: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    let vh = window.innerHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // classify screen size into small|mid|large screen for mobile|tablet|laptop
    let scr = "small";
    if (window.innerWidth > 600) scr = "mid";
    if (window.innerWidth > 1100) scr = "large";
    return scr;
  }
  const [screen, setScreen] = useState(getScreenSize());

  window.onresize = (ev) => {
    setScreen(getScreenSize());
  };
  
  return (
    <Suspense fallback="loading" >
      <BrowserRouter >
        <Switch >
          <Route path="/" >
            <Home screen={screen} />
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}
