import React from 'react';
import Acceuil from './section/acceuil';
import Apropos from './section/apropos';
import Equipe from './section/equipe';
import Service from './section/service';
import Valeur from './section/valeur';
import Contact from './section/contact';
import Utils from '../../utils/utils';
import 'antd/dist/antd.css';
import ReactGA from 'react-ga';

export default function Home(props) {
	ReactGA.initialize('UA-179408149-1')
	ReactGA.pageview(window.location.pathname)
	// module la transparence du fond du bandeau en fonction du scroll
	let lastScrollY = 0;
	let ticking = false;
	let updateScroll = (e) => {
		// lastScrollY = e.target.scrollTop;
		lastScrollY = document.getElementsByClassName('App')[0].scrollTop;
		let updateScrollTop = (scrollTop) => {
			let gbColor = window.getComputedStyle(document.documentElement).getPropertyValue("--color-bg-light");
			let util = Utils();
			let nouveauBgColor = util.rgb2rgba(gbColor, 1 - (4 * scrollTop / window.innerHeight));
			document.getElementById('bandeau').style.setProperty("background-color", nouveauBgColor)
		}
		if (!ticking) {
			window.requestAnimationFrame(() => {
				updateScrollTop(lastScrollY);
				ticking = false;
			});
		}
		ticking = true;
	};

	// Anime le scroll de section en section (par l'icone chevron down)
	let scrollDownOneSection = (e) => {
		let app = document.getElementsByClassName('App')[0];
		let currentScrollY = app.scrollTop;
		let debut;
		let dureeAnimation = 500; // 0.5 seconde
		let pxPARms = (window.innerHeight * 0.9) / dureeAnimation;
		let scrollSection = (timestamp) => {
			if (debut === undefined) debut = timestamp;
			let ecouleCumul = timestamp - debut;
			app.scrollTo(0, Math.min((pxPARms * ecouleCumul) + currentScrollY, window.innerHeight + currentScrollY));
			updateScroll()
			if (ecouleCumul < dureeAnimation) setTimeout(() => { window.requestAnimationFrame(scrollSection) }, 20);
		}
		window.requestAnimationFrame(scrollSection);
	}
	// Retour rapide en haut (par l'icone arrow up)
	let scrollbackup = (e) => {
		let app = document.getElementsByClassName('App')[0];
		let currentScrollY = app.scrollTop;
		let debut;
		let dureeAnimation = 500; // 0.5 seconde
		let pxPARms = currentScrollY / dureeAnimation;
		let scrollSection = (timestamp) => {
			if (debut === undefined) debut = timestamp;
			let ecouleCumul = timestamp - debut;
			app.scrollTo(0, Math.min(currentScrollY - (pxPARms * ecouleCumul), currentScrollY));
			if (ecouleCumul < dureeAnimation) setTimeout(() => { window.requestAnimationFrame(scrollSection) }, 20);
		}
		app.scrollTo(0, 0);
		window.requestAnimationFrame(scrollSection);
	}


	return (
		<div className="App" onScroll={updateScroll}>
			{/* <Maintenance screen={props.screen} /> */}
			<Acceuil DownChevronClick={scrollDownOneSection} screen={props.screen} />
			<Apropos DownChevronClick={scrollDownOneSection} screen={props.screen} />
			<Equipe DownChevronClick={scrollDownOneSection} UpArrowClick={scrollbackup} screen={props.screen} />
			<Service DownChevronClick={scrollDownOneSection} UpArrowClick={scrollbackup} screen={props.screen} />
			<Valeur DownChevronClick={scrollDownOneSection} UpArrowClick={scrollbackup} screen={props.screen} />
			<Contact DownChevronClick={scrollDownOneSection} UpArrowClick={scrollbackup} screen={props.screen} />
		</div>
	);
}
