import './section.css';
import './accueil.css';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/img/chevron-down_light.png';
import Bandeau from '../bandeau/bandeau';

export default function Acceuil(props) {
	const { t } = useTranslation(['translation', 'section_accueil']);
	return (
		<section className='section accueil'>
			<Bandeau screen={props.screen} />
			<div className="bienvenue-bloc">
				<p >{t('section_accueil:message')}</p>
				<h2>{'> '}{t('section_accueil:bienvenue')}</h2>
			</div>
			<h3 onClick={(e) => props.DownChevronClick(e)}>{t('section_accueil:decouvrir')}</h3>
			<img className="go-down" src={chevron} alt="allerEnBas" onClick={(e) => props.DownChevronClick(e)} />
		</section>
	);
}